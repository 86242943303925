import Vue from 'vue'
import axios from "axios"
import VueAxios from "vue-axios"
import App from './index.vue'

Vue.config.productionTip = false
Vue.use(VueAxios, axios);
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://assistant.easy-more.com/api';

new Vue({
  render: h => h(App),
}).$mount('#app')